import { graphql } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import ContactSection from "../components/contact/ContactSection/ContactSection";
import Layout from "../components/layout";
import SEO from "../components/seo";

interface ContactPageProps {
  location: Location;
  data: {
    bgImage: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
}

const ContactPage: React.FC<ContactPageProps> = ({ location }) => {
  return (
    <Layout>
      <SEO
        title="Fast Estimate for Residential &amp; Commercial Painting Charlotte"
        description="Contact us to schedule a free estimate. We specialize in kitchen cabinets refinishing, interior &amp; exterior home painting, and commercial painting."
        slug={location.pathname}
      />

      <ContactSection />

      <section className="pt-0 bg-gray-100">
        <div className="container-wide">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3263.4118445769745!2d-80.95799318475642!3d35.12139468032861!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88569a39b6aafff1%3A0xa962dd5b039a12ba!2s10721%20John%20Price%20Rd%2C%20Charlotte%2C%20NC%2028273%2C%20USA!5e0!3m2!1sen!2spl!4v1626338685616!5m2!1sen!2spl"
            loading="lazy"
            className="w-full h-[700px]"
          />
        </div>
      </section>
    </Layout>
  );
};

export default ContactPage;

export const pageQuery = graphql`
  query {
    bgImage: imageSharp(original: { src: { regex: "/exterior-painting-8/" } }) {
      id
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
`;
